import React from 'react'
import { RESOURCES } from '../../components/constant'
import Layout from '../../components/layout'
import PageStructure from '../../components/pagestructure'
import SEO from '../../components/seo'
const UsefulBooks = () => {
  return (
    <div>
      <Layout>
        <SEO title="Useful Books" />
        <PageStructure title="Useful Books" desc="TESTIG" mode={RESOURCES} />
      </Layout>
    </div>
  )
}

export default UsefulBooks
